/**
 * Enum `JrniServiceCategory` corresponds to values for query string `format` of URL of AppointmentBooking MicroFrontEnd.
 *
 * Note: the Amend page URL do not have `format` in the URL.
 * For this case, we use `CategoryName` enum, taken from `PurchaseResponse`.
 */
export var JrniServiceCategory;
(function (JrniServiceCategory) {
    JrniServiceCategory["Online"] = "online";
    JrniServiceCategory["InStore"] = "inStore";
    JrniServiceCategory["EnergyAssessment"] = "energy-assessment";
    JrniServiceCategory["EnergyAssessmentInStore"] = "energy-assessment-instore";
    JrniServiceCategory["GardenDesignInstore"] = "garden-instore";
})(JrniServiceCategory || (JrniServiceCategory = {}));
/**
 * Enum `CategoryName` corresponds to values for field `category_name` of the Booking Interface.
 * This field is present in the `Booking` object, which can be retrieved with the Purchase request.
 */
export var CategoryName;
(function (CategoryName) {
    CategoryName["EnergyAssessment"] = "energy_assessment";
    CategoryName["InStore"] = "in_store_appointments";
    CategoryName["Online"] = "virtual_appointments";
})(CategoryName || (CategoryName = {}));
