import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { _t } from '@hip/translations';
import { Container, MainContent, Loading, Toast, } from '@realm-kf/halcyon-components-react';
import { Layout } from '../../components/layout';
import { VirtualHubList } from '../../components/virtualHubList/virtualHubList';
import { StoreList } from '../../components/storeList/storeList';
import { actions as storesActions, getActiveStore, getVirtualHubStore, getStoreError, getStores, getStoresIsLoading, } from '../../core/location/stores';
import { getSelectedStoreErrror } from '../../core/location/selectedStore';
import { getCategory, JrniServiceCategory } from '../../core/utils';
import { actions as analyticsActions, AnalyticsEventMap, AnalyticsEvents, makeAnalyticsPayload, } from '../../core/analytics';
import { useHeaderTranslations } from '../../hooks/useHeaderTranslations';
import { MapOfStores } from './mapOfStores';
import { LocationSearch } from './locationSearch';
import styles from './storeStep.css';
var StoreStep = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var stores = useSelector(getStores);
    var isStoresLoading = useSelector(getStoresIsLoading);
    var activeStore = useSelector(getActiveStore);
    var virtualHub = useSelector(getVirtualHubStore);
    var category = useSelector(getCategory);
    var selectedStoreError = useSelector(getSelectedStoreErrror);
    var storeError = useSelector(getStoreError);
    var showStoresList = stores && activeStore && !virtualHub;
    var showFullDetails = category === JrniServiceCategory.InStore ||
        category === JrniServiceCategory.EnergyAssessmentInStore;
    var _a = useHeaderTranslations(), title = _a.title, subtitle = _a.subtitle;
    var handleSetActiveStore = function (store) {
        dispatch(storesActions.setActiveStore(store));
    };
    var showError = !!(selectedStoreError || storeError);
    useEffect(function () {
        if (showError) {
            dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.GENERIC_ERROR, AnalyticsEventMap[AnalyticsEvents.SELECT_STORE].action)));
        }
    }, [showError]);
    return (React.createElement(Layout, { title: title, subtitle: subtitle, footerProps: {
            secondaryLabel: _t('appointment_booking.footer.back'),
            secondaryClick: function () { return history.goBack(); },
        } },
        React.createElement(LocationSearch, null),
        React.createElement(MainContent, null,
            React.createElement(Container, { classes: styles.Container },
                React.createElement("div", { className: styles.ResultsWrapper },
                    virtualHub && (React.createElement(VirtualHubList, { virtualHub: virtualHub, clickStoreWrapper: handleSetActiveStore })),
                    showStoresList && ( // TODO: remove activeStore after halcyon Accordion component will be fixed
                    React.createElement(StoreList, { "data-testid": "store-list", activeStore: activeStore, showFullDetails: showFullDetails, stores: stores, clickStoreWrapper: handleSetActiveStore })),
                    activeStore && React.createElement(MapOfStores, null),
                    React.createElement(Loading, { primary: true, show: isStoresLoading })),
                React.createElement(Toast, { content: _t('appointment_booking.common.sth_went_wrong'), fromTop: "0", isVisible: showError, variant: "warning" })))));
};
export { StoreStep };
